.aboutSection {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
  background: linear-gradient(
    to right,
    var(--claro) 50%,
    var(--color-midnightblue-200) 50%
  );
  width: 100%;
  text-align: justify;
  font-size: var(--font-size-5xl);
  color: var(--colors-white);
  font-family: var(--font-inter);
}

.container {
  display: flex;
  flex-direction: row !important;
}

.aboutText,
.logo {
  padding: 3rem;
  width: 50%;
}

.logo {
  text-align: center;
}

.logoIcon {
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .aboutSection {
    background: linear-gradient(
      to bottom,
      var(--claro) 50%,
      var(--color-midnightblue-200) 50%
    );
    box-shadow: none;
    font-size: var(--font-size-4xl);
  }

  .container {
    flex-direction: column !important;
    height: 100vh;
  }

  .aboutText,
  .logo {
    width: 100%;
  }

  .aboutText .Tittle {
    margin: auto;
  }

  .logo {
    margin: auto;
  }

  .logo img {
    width: 100%;
  }
}
