.container {
  margin: auto;
  min-width: 85vw;
  max-width: 944px;
  height: 100%;
  display: flex;
  align-items: center;
}

.header {
  width: 100%;
  height: 95vh;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--escuro);
  font-family: var(--font-calibri);
  background-image: url("/public/header-bg.png");
  background-size: 105%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.soluesPara {
  letter-spacing: 0.01em;
}

.title {
  display: inline-block;
  width: 19.188rem;
  height: 8.563rem;
}

.subTitleChild {
  background-color: var(--color);
  width: 95%;
  height: 0.438rem;
}

.brokerSeguros,
.subTitle {
  width: 11.813rem;
}

.brokerSeguros {
  font-family: var(--font-calibri);
  text-transform: uppercase;
  display: inline-block;
  height: 1.813rem;
}

.subTitle {
  height: 2.438rem;
  font-size: var(--font-size-6xl);
  color: var(--color-midnightblue-200);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.callBtn {
  box-shadow: 2px 2px 0 var(--escuro);
  font-weight: 800;
  border-radius: var(--br-3xs);
  background-color: var(--color);
  width: 17.938rem;
  height: 3.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: var(--font-size-7xl);
  cursor: pointer;
}

.callBtn:hover {
  box-shadow: none;
  background-color: var(--btn-hover);
}

.heroText {
  width: 19.188rem;
  height: 18.313rem;
  margin: 15vh 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
}

@media only screen and (max-width: 768px) {
  .header {
    height: fit-content;
    background-size: 200%;
    background-position: center;
  }

  .container {
    flex-direction: column;
  }

  .brokerSeguros {
    height: 1.5rem;
    font-size: 1rem;
  }

  .title {
    width: 100%;
    height: 10rem;
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
}
