.card {
  margin: 1rem;
  box-shadow: 0 5px 0#191a23;
  border-radius: var(--br-26xl);
  box-sizing: border-box;
  width: 25rem;
  height: 15rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-31xl);
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s;
}

.cardFront {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flippedCard {
  transform: translateX(-100%) rotateY(-180deg);
}

.cardBack {
  transform: rotateY(180deg);
}

.label2,
.label3 {
  font-size: larger;
}

.displayNone {
  display: none;
}

.labelBox .label1,
.labelBox .label2,
.labelBox .label3 {
  font-family: var(--h2);
  font-weight: 400;
  width: fit-content;
  padding: 0.2rem 0.2rem;
  margin-bottom: 0.2rem;
  border-radius: 0.4rem;
}

.darkColors {
  background-color: var(--dark-card-bg) !important;
  border: 1px solid var(--dark-card-stroke);
}

.darkColors .SaibaMais {
  color: var(--dark-card-text-bg) !important;
}

.darkColors .labelBox .label1,
.darkColors .labelBox .label2,
.darkColors .labelBox .label3 {
  font-weight: 500;
  color: var(--dark-card-bg);
  background-color: var(--dark-card-text-bg);
}

.lightColors {
  background-color: var(--light-card-bg) !important;
  border: 1px solid var(--light-card-stroke);
}

.lightColors .SaibaMais {
  color: var(--light-card-text-bg) !important;
}

.lightColors .labelBox .label1,
.lightColors .labelBox .label2,
.lightColors .labelBox .label3 {
  color: var(--claro);
  background-color: var(--light-card-text-bg);
}

.cardImg {
  width: 40%;
}

.cardFront .headingAndLink {
  cursor: pointer;
}
.headingAndLink {
  height: 13rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.flippedCard {
  background-color: var(--claro) !important;
  border: 1px solid var(--dark-card-stroke);
}

.bottomDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.CoteAgora {
  display: flex;
  cursor: pointer;
}

.lightColors .backCardImg {
  background-color: var(--light-card-bg) !important;
  border: 2px solid var(--light-card-stroke);
}

.darkColors .backCardImg {
  background-color: var(--dark-card-bg) !important;
  border: 2px solid var(--dark-card-stroke);
}

.backCardImg {
  width: 25%;
  border-radius: 40%;
  padding: 2% 5%;
  align-self: flex-end;
  cursor: pointer;
}

.backText {
  font-size: small;
  line-height: 1.3rem;
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  .backText {
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: justify;
  }
}
