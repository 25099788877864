.container {
  margin: auto;
  min-width: 85vw;
  max-width: 944px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicesSection {
  padding-top: 5rem;
  padding-bottom: 5rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
  background-color: var(--claro);
  width: 100%;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-midnightblue-100);
  font-family: var(--font-inter);
}

.sectionSepIcon {
  width: 6.013rem;
  height: 3.631rem;
  object-fit: contain;
  align-self: flex-start;
}

.cards {
  display: flex;
  padding: 2rem;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-content: space-around;
}

.sectionSubtitle {
  letter-spacing: 0.01em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57.831rem;
  height: 4.569rem;
}

.link {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 1.4rem;
}

.link:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.link div {
  margin: 0.6rem;
}

.reverse,
.notReverse {
  display: flex;
  width: 100%;
  height: max-content;
  margin: auto;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .container {
    overflow: hidden;
  }

  .sectionSubtitle {
    width: 80%;
    margin-top: 2rem;
  }

  .sectionSepIcon {
    margin-left: 1rem;
  }

  .link {
    width: 80%;
    font-size: 1.2rem;
  }

  .reverse {
    flex-direction: column-reverse;
  }

  .notReverse {
    flex-direction: column;
  }
}
