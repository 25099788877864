.desktop {
  width: 100%;
  background-color: var(--colors-white);
}

.floatingButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;
  transition: filter 0.3s ease;
}

.floatingButton img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.floatingButton:hover img {
  filter: brightness(1.05);
}
