.text {
  font-weight: 900;
}

.border {
  height: 12.9%;
  width: 98%;
  background-color: var(--color);
}

.tittle {
  max-width: 100%;
  height: 62px;
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--escuro);
  font-family: var(--font-inter);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .tittle {
    font-size: 2rem;
    width: 80%;
  }

  .text {
    padding-bottom: 0.5rem;
  }

  .border {
    height: 10%;
    width: 80%;
  }
}
