.formSection {
  background: url("/public/form-section-bg.png") left,
    linear-gradient(
      to right,
      var(--color-midnightblue-100) 50%,
      var(--color-midnightblue-100) 50%
    );
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-color: var(--color-midnightblue-100);
  width: 100%;
  display: flex;
  text-align: left;
  font-family: var(--font-inter);
  padding: 5rem;
}

.formContainer {
  min-height: 85vh;
  justify-content: space-around;
  flex-direction: row !important;
}

.formBox {
  width: 40%;
}

.textBox {
  width: 35%;
  color: var(--claro);
}

.title {
  font-size: var(--font-size-29xl);
}

.text {
  font-size: var(--font-size-3xl);
  letter-spacing: 1px;
  line-height: 2rem;
}

.formBox {
  border-radius: var(--br-3xs);
  height: 70vh;
  background-color: var(--claro);
  display: flex;
  flex-direction: column;
}

.formContainerInner,
.form {
  display: flex;
  flex-direction: column;
  height: 95%;
  justify-content: space-between;
}

.formTitle {
  font-size: var(--font-size-5xl);
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 3rem;
  color: var(--escuro);
  text-align: center;
  margin-top: 0.4rem;
}

.input {
  font-family: var(--font-inter) !important;
}

.inputLabel {
  color: var(--escuro) !important;
  font-family: var(--font-inter) !important;
}

.button {
  background-color: var(--escuro) !important;
  color: #fff;
  font-family: var(--font-inter) !important;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .formContainer,
  .formSection .container {
    flex-direction: column !important;
    max-width: 100% !important;
    min-width: 90% !important;
  }

  .formSection {
    padding: 2rem;
  }

  .textBox,
  .formBox {
    width: 100%;
  }

  .title {
    font-size: var(--font-size-17xl);
  }

  .text {
    font-size: var(--text-label-size);
    letter-spacing: 1px;
    line-height: 1.5rem;
  }
}
