.footer {
  font-family: var(--font-inter);
  font-weight: 500;
  color: var(--escuro);
}

.logoFooter {
  max-width: 50%;
}

.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.info {
  width: 75%;
}

.infoBlock {
  text-align: start;
  padding: var(--padding-xs);
  display: flex;
  align-items: center;
}

.infoBlock img {
  margin: var(--padding-xs);
}

.infoBlock a {
  color: var(--escuro);
  text-decoration: none;
}

.infoBlock a:hover {
  color: var(--color);
}

.menuFooter {
  margin: auto;
}

.menuFooter div {
  padding: var(--padding-xs);
}

.link {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 1.4rem;
}

.link:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.link div {
  margin: 0.6rem;
}
