.section2 {
  background-image: url("/public/section2-bg.svg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 95vh;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--escuro);
  font-family: var(--font-inter);
  display: flex;
}

.container {
  min-height: 60vh;
  justify-content: space-between;
}

.sectionSepIconBegin {
  align-self: flex-start;
}

.sectionSepIconEnd {
  align-self: flex-end;
}

.sectionSubtitle {
  font-size: var(--font-size-2xl);
  letter-spacing: 0.01em;
  font-weight: 500;
  color: var(--claro);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 2rem;
}

.callBtn {
  box-shadow: 2px 2px 0 var(--escuro);
  font-weight: 800;
  border-radius: var(--br-3xs);
  background-color: var(--color);
  width: 17.938rem;
  height: 3.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: var(--font-size-7xl);
  cursor: pointer;
}

.callBtn:hover {
  box-shadow: none;
  background-color: var(--btn-hover);
}

@media only screen and (max-width: 768px) {
  .container {
    width: 80%;
  }

  .sectionSubtitle {
    font-weight: 300;
    line-height: 2rem;
  }
}
