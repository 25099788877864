.contato {
  background-color: var(--color);
  width: 100%;
  text-align: center;
  display: flex;
}

.container {
  min-height: 100vh;
  justify-content: space-between;
}

.content {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--gap-mini);
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.map,
.contacts {
  max-width: 49.8%;
  min-width: 49%;
}

.tittle,
.logo,
.info,
.map {
  width: 100%;
  border-radius: var(--br);
}

.tittle {
  margin: var(--gap-mini);
  font-weight: 900;
  color: var(--claro);
  font-size: var(--font-size-17xl);
  background-color: var(--color-midnightblue-100);
  padding: var(--padding-13xl);
  font-family: var(--font-inter);
}

.logo {
  margin-bottom: var(--gap-mini);
}

.logo,
.info,
.map {
  background-color: var(--claro);
  padding: var(--padding-6xs);
  font-size: var(--text-label-size);
  color: var(--color-black);
  font-family: var(--font-inter);
  font-weight: 600;
}

.map {
  display: flex;
  overflow: hidden;
}

.map img,
.map a {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.map img:hover {
  transform: scale(1.1);
}

.infoBlock {
  text-align: start;
  padding: var(--padding-6xs);
}

.infoBlock div {
  display: flex;
  align-items: center;
}

.infoBlock div img {
  margin: 0.5rem;
}

.infoBlock a {
  color: var(--escuro);
  text-decoration: none;
}

.infoBlock a:hover {
  color: var(--color);
}

@media only screen and (max-width: 600px) {
  .container {
    max-width: 90% !important;
  }

  .tittle {
    margin: var(--gap-mini) auto;
    font-size: var(--font-size-5xl);
  }

  .logo {
    display: none;
  }

  .content {
    flex-direction: column;
  }

  .container {
    justify-content: center;
  }

  .map,
  .contacts {
    min-width: 100%;
    margin: 5px auto;
  }
}
