.smallFooter {
  background-color: var(--color-midnightblue-100);
  width: 100%;
  padding: var(--padding-6xs);
  height: 6vh;
}

.smallFooter,
.smallFooter a {
  color: var(--claro);
  font-family: var(--font-inter);
}
