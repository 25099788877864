.logoIcon {
  width: 10.313rem;
  height: 3.938rem;
  object-fit: cover;
}

.NavBar {
  margin: auto;
  font-size: var(--text-paragraph-size);
  font-family: var(--font-inter);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.25rem;
  min-width: 85vw;
  max-width: 944px;
  border-radius: 0 0 var(--br-3xs) var(--br-3xs);
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0.5rem;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.5s ease;
  z-index: 1000;
}

.navItens {
  color: var(--escuro);
  width: 100%;
  height: 3.063rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.navItens .callBtn {
  width: 12rem;
  height: 3rem;
  font-size: var(--font-size-6x1);
}

.callBtn {
  box-shadow: 2px 2px 0 var(--escuro);
  font-weight: 800;
  border-radius: var(--br-3xs);
  background-color: var(--color);
  width: 17.938rem;
  height: 3.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: var(--font-size-7xl);
  cursor: pointer;
}

.callBtn:hover {
  box-shadow: none;
  background-color: var(--btn-hover);
}

.contato,
.produtos,
.sobreNs {
  font-weight: 800;
  display: inline-block;
  height: 1.219rem;
  color: var(--escuro);
  transition: color 0.3s ease;
}

.contato:hover,
.produtos:hover,
.sobreNs:hover {
  color: var(--color);
  cursor: pointer;
}

.logoIcon,
.callBtn {
  cursor: pointer;
}

.sobreNs {
  width: 5.75rem;
}

.contato,
.produtos {
  width: 6.013rem;
}

.contato {
  width: 5.156rem;
}

.displayNone {
  display: none;
}

@media only screen and (max-width: 600px) {
  .displayBlock {
    display: block;
    align-self: flex-start;
  }

  .closedSmMenu {
    display: none;
  }

  .NavBar {
    justify-content: flex-start;
    border-radius: 0;
    width: 100%;
    top: 0;
    margin: none;
  }

  .navItens {
    flex-direction: column;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .navItens .logoIcon {
    display: none;
  }

  .navItens div {
    width: fit-content;
    margin: 5px;
  }
}
