.icon {
  width: 2rem;
  height: 2rem;
}

.SaibaMais {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--colors-white);
  font-family: var(--h2);
}
