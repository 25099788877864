@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=FontAwesome:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Calibri:wght@700&display=swap");
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  line-height: normal;
}

html {
  font-size: 16px;
}

.container {
  margin: auto;
  min-width: 85vw;
  max-width: 944px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.claro {
  color: var(--claro);
}

.escuro {
  color: var(--escuro);
}

:root {
  /* fonts */
  --font-inter: Inter;
  --font-calibri: Calibri;
  --h2: "Space Grotesk";
  --font-fontawesome: FontAwesome;
  --text-label: "DM Sans";

  /* font sizes */
  --text-paragraph-size: 1rem;
  --font-size-7xl: 1.625rem;
  --font-size-6xl: 1.563rem;
  --font-size-29xl: 3rem;
  --font-size-17xl: 2.25rem;
  --font-size-5xl: 1.5rem;
  --font-size-4xl: 1.3rem;
  --font-size-xl: 1rem;
  --h2-size: 2.5rem;
  --h3-size: 1.875rem;
  --font-size-3xl: 1.375rem;
  --font-size-41xl: 3.75rem;
  --font-size-23xl: 2.625rem;
  --font-size-9xl: 1.75rem;
  --text-label-size: 0.875rem;
  --font-size-13xl: 2rem;
  --buttons-middle-size: 1.125rem;
  --font-size-lgi-8: 1.238rem;

  /* Colors */
  --dark-card-bg: #2a0f5b;
  --dark-card-stroke: #290c5d;
  --dark-card-text-bg: #f59124;
  --light-card-bg: #f59124;
  --light-card-stroke: #d37e21;
  --light-card-text-bg: #2a0f5b;
  --btn-hover: #da8221;
  --colors-white: #fff;
  --color-midnightblue-100: #2a0f5b;
  --color-midnightblue-200: #290c5f;
  --escuro: #290c5d;
  --colors-heading: #1e0e62;
  --color-midnightblue-300: rgba(42, 15, 91, 0.5);
  --color: #f59124;
  --claro: #fefefd;
  --colors-text: rgba(21, 20, 57, 0.4);
  --color-gray-100: rgba(255, 255, 255, 0.5);
  --green: #b9ff66;
  --color-black: #000;
  --color-sandybrown: #eda24b;
  --color-thistle: #c1b6cc;
  --action-primary: #25dac5;
  --colors-grey: #ebeaed;

  /* Gaps */
  --gap-74xl: 5.812rem;
  --gap-mini: 0.6rem;
  --gap-11xs: 0.125rem;

  /* Paddings */
  --padding-3xs: 0.625rem;
  --padding-13xl: 2rem;
  --padding-31xl: 3.125rem;
  --padding-6xs: 0.437rem;
  --padding-xs: 0.3rem;

  /* Border radiuses */
  --br: 25px;
  --br-3xs: 10px;
  --br-26xl: 45px;
  --br-6xs: 7px;
  --br-31xl: 50px;
  --br-17xl: 36px;
  --br-81xl: 100px;
  --br-9xs: 4px;
  --br-7xs: 6px;
}

@media only screen and (max-width: 768px) {
  :root {
    font-size: 10px;
  }
}
