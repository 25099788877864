.historySection {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
  background: linear-gradient(
    to right,
    var(--color-midnightblue-200) 50%,
    var(--claro) 50%
  );
  width: 100%;
  text-align: justify;
  font-size: var(--font-size-5xl);
  color: var(--colors-white);
  font-family: var(--font-inter);
}

.container {
  display: flex;
  flex-direction: row !important;
}

.historyText,
.img {
  padding: 3rem;
  width: 50%;
}

.img {
  text-align: center;
}

.historyImgIcon {
  max-width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .historySection {
    background: var(--color-midnightblue-200);
    font-size: var(--font-size-4xl);
  }

  .historyText {
    width: 100%;
  }

  .historyText .Tittle {
    margin: auto;
  }

  .img {
    display: none;
  }
}
